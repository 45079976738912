<template>
  <div
    class="tab-pane fade show active"
    id="pills-details"
    role="tabpanel"
    aria-labelledby="pills-details-tab"
  >
    <ActiveView
      v-if="isActiveState"
      :accountDetails="accountDetails"
      :accountState="accountState"
    />

    <OtherStates v-else :accountDetails="accountDetails" />
    <hr />
    <div>
      <div class="row mt5">
        <div class="col-6">
          <div class="account_item_head">
            <p class="account_item_head_text text--white">general</p>
          </div>
          <div class="row no-gutters account_item align-items-center">
            <div class="col-6">
              <span
                class="
                  text--black text--opaq2 text--small text--bold
                  account_item_title
                "
              >
                Account id
              </span>
              <span class="text--black text--regular account_item_subtitle">
                {{ accountDetails.uniqueId }}
              </span>
            </div>
          </div>
          <div class="row no-gutters account_item align-items-center">
            <div class="col-6">
              <span
                class="
                  text--black text--opaq2 text--small text--bold
                  account_item_title
                "
              >
                Assigned to Branch
              </span>
              <span class="text--black text--regular account_item_subtitle">
                {{ branchName.branchName }}
              </span>
            </div>
            <div class="col-6">
              <span
                class="
                  text--black text--opaq2 text--small text--bold
                  account_item_title
                "
              >
                Account Officer
              </span>
              <span class="text--black text--regular account_item_subtitle">
                {{ accountDetails.accountOfficerName }}
              </span>
            </div>
          </div>
          <div class="row no-gutters account_item align-items-center">
            <div class="col-6">
              <span
                class="
                  text--black text--opaq2 text--small text--bold
                  account_item_title
                "
              >
                Account State
              </span>
              <span class="text--black text--regular account_item_subtitle">
                {{ accountDetails.accountStatus }}
              </span>
            </div>
          </div>
          <div class="row no-gutters account_item align-items-center">
            <div class="col-6">
              <span
                class="
                  text--black text--opaq2 text--small text--bold
                  account_item_title
                "
              >
                Currency
              </span>
              <span class="text--black text--regular account_item_subtitle">
                {{ accountDetails.currency.currencyCode }}
              </span>
            </div>
            <div class="col-6">
              <span
                class="
                  text--black text--opaq2 text--small text--bold
                  account_item_title
                "
              >
                Activation Date
              </span>
              <span class="text--black text--regular account_item_subtitle">
                {{ accountDetails.activationDate | moment }}
              </span>
            </div>
          </div>
          <div class="row no-gutters account_item align-items-center">
            <div class="col-6">
              <span
                class="
                  text--black text--opaq2 text--small text--bold
                  account_item_title
                "
              >
                Account Name
                <!-- Product -->
              </span>
              <span
                class="
                  text--black text--regular
                  account_item_subtitle
                  text--primary
                "
              >
                {{ accountDetails.savingsAccountName }}
              </span>
            </div>
            <div class="col-6">
              <span
                class="
                  text--black text--opaq2 text--small text--bold
                  account_item_title
                "
              >
                Product Name
              </span>
              <span
                class="text--black text--regular account_item_subtitle overlap"
              >
                {{ accountDetails.productName }}
              </span>
            </div>
          </div>
          <div class="row no-gutters account_item align-items-center">
            <div class="col-6">
              <span
                class="
                  text--black text--opaq2 text--small text--bold
                  account_item_title
                "
              >
                Interest Rate
              </span>
              <span class="text--black text--regular account_item_subtitle">
                {{ accountDetails.interestRate }}% Per Month
              </span>
            </div>
            <div class="col-6">
              <span
                class="
                  text--black text--opaq2 text--small text--bold
                  account_item_title
                "
              >
                Interest Rate Term
              </span>
              <span class="text--black text--regular account_item_subtitle">
                {{ accountDetails.interestRateTerm }}
              </span>
            </div>
          </div>
          <div class="row no-gutters account_item align-items-center">
            <div class="col-6">
              <span
                class="
                  text--black text--opaq2 text--small text--bold
                  account_item_title
                "
              >
                Interest Calculation Method
              </span>
              <span class="text--black text--regular account_item_subtitle">
                {{ accountDetails.interestCalculationMethod }}
              </span>
            </div>
            <div class="col-6">
              <span
                class="
                  text--black text--opaq2 text--small text--bold
                  account_item_title
                "
              >
                Interest paid into Account
              </span>
              <span class="text--black text--regular account_item_subtitle">
                {{ accountDetails.inteterestPaid }}
              </span>
            </div>
          </div>
          <div class="row no-gutters account_item align-items-center">
            <div class="col-6">
              <span
                class="
                  text--black text--opaq2 text--small text--bold
                  account_item_title
                "
              >
                Account Type
                <!-- Product Type -->
              </span>
              <span class="text--black text--regular account_item_subtitle">
                {{ accountDetails.accountType }}
              </span>
            </div>
            <div class="col-6">
              <span
                class="
                  text--black text--opaq2 text--small text--bold
                  account_item_title
                "
              >
                Allowed for Offset
              </span>
              <span class="text--black text--regular account_item_subtitle">
                No
              </span>
            </div>
          </div>
          <div class="row no-gutters account_item align-items-center">
            <div class="col-6">
              <span
                class="
                  text--black text--opaq2 text--small text--bold
                  account_item_title
                "
              >
                Maximum Withdrawal Amount
              </span>
              <span class="text--black text--regular account_item_subtitle">
                {{ accountDetails.maxWithdrawalAmount | currency(accountDetails.currency.currencySymbol) }}
              </span>
            </div>
            <div class="col-6">
              <span
                class="
                  text--black text--opaq2 text--small text--bold
                  account_item_title
                "
              >
                Opening Balance
              </span>
              <span class="text--black text--regular account_item_subtitle">
                {{ accountDetails.openingBalance | currency(accountDetails.currency.currencySymbol) }}
              </span>
            </div>
          </div>
        </div>
        <div class="w-50">
          <div class="col-12">
            <div class="account_item_head">
              <p class="account_item_head_text text--white">Account Details</p>
            </div>
            <div class="row no-gutters account_item align-items-center">
              <div class="col-6">
                <span
                  class="
                    text--black text--opaq2 text--small text--bold
                    account_item_title
                  "
                >
                  Matured Date
                </span>
                <span class="text--black text--regular account_item_subtitle">
                  {{ accountDetails.maturityDate | moment }}
                </span>
              </div>
              <div class="col-6">
                <span
                  class="
                    text--black text--opaq2 text--small text--bold
                    account_item_title
                  "
                >
                  Balance
                </span>
                <span class="text--black text--regular account_item_subtitle">
                  {{ accountDetails.balance | currency(accountDetails.currency.currencySymbol) }}
                </span>
              </div>
            </div>
            <div class="row no-gutters account_item align-items-center">
              <div class="col-6">
                <span
                  class="
                    text--black text--opaq2 text--small text--bold
                    account_item_title
                  "
                >
                  Available Balance
                </span>
                <span class="text--black text--regular account_item_subtitle">
                  <!-- {{ accountDetails.forwardAvailableBalance | currency }} -->
                  {{ accountDetails.availableBalance | currency(accountDetails.currency.currencySymbol) }}
                </span>
              </div>
              <div class="col-6">
                <span
                  class="
                    text--black text--opaq2 text--small text--bold
                    account_item_title
                  "
                >
                  Interest Accrued
                </span>
                <span class="text--black text--regular account_item_subtitle">
                  {{ accountDetails.accruedInterest | currency(accountDetails.currency.currencySymbol) }}
                </span>
              </div>
            </div>
          </div>

          <!--Custom Field -->
          <div v-if="accountDetails.customefield.items !== ''">
            <div
              class="mt5 mb5 col-12"
              v-for="customField in accountDetails.customefield.items"
              :key="customField.id"
              v-show="userCanViewDepositAccount"
            >
              <div v-if="customField.fields !== null">
                <div v-if="customField.fields.length > 0">
                  <div class="account_item_head">
                    <p class="account_item_head_text text--white">
                      {{ customField.fieldSetName }}
                    </p>
                  </div>
                  <div class="row no-gutters account_item align-items-start">
                    <div
                      class="col-6 p-4"
                      v-for="field in customField.fields"
                      :key="field.id"
                    >
                      <span
                        class="
                          text--black text--opaq2 text--small text--bold
                          account_item_title
                        "
                      >
                        {{ field.fieldName }}
                        <a
                          class="
                            text--primary text--capital
                            account_item_head_text
                            mr-3
                          "
                          v-if="userCanEditAccount"
                          @click="
                            editField(
                              customField.fieldSetKey,
                              field.fieldKey,
                              field.value,
                              field.isDefault,
                              field.isRequired,
                              customField.fields,
                              true,
                            )"
                          >edit</a
                        >
                      </span>
                      <span
                        v-if="['customer link', 'group link', 'user link'].includes(field.dataType.toLowerCase())"
                        class="
                          table__body__text table__text
                          text--capital text--primary
                          cursor
                          account_item_subtitle
                        "
                        style="cursor: pointer; word-wrap: break-word"
                        @click="routeToLinkView(field.linkKey, field.dataType)"
                      >
                        {{ field.linkName }}
                      </span>
                      <span v-else class="text--black text--regular account_item_subtitle" style="word-wrap: break-word;">
                        {{ field.value }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "@/core/services/general.service";
import moment from "moment";
export default {
  props: {
    accountDetails: [Array, Object],
    accountState: [Array, Object],
    userCanEditAccount: Boolean,
    userCanViewDepositAccount: Boolean,
  },
  components: {
    ActiveView: () =>
      import(
        /* WebpackChunkName: "ActiveView" */ "@/components/deposit/activeStateView.vue"
      ),
    OtherStates: () =>
      import(
        /* WebpackChunkName: OtherStates" */ "@/components/deposit/otherState.vue"
      ),
  },
  data() {
    return {
      branchName: "",
      isEditing: false,
    };
  },
  methods: {
    getBranchDetails() {
      this.branchName = "";
      ApiService.get(
        `Branch/${this.accountDetails.assignedBranchKey}`
      ).then((resp) => {
        this.branchName = resp.data.data;
      });
    },
    editField(key, k, v, d, def, req) {
      this.isEditing = true;
      this.$emit("editField", key, k, v, d, def, req, this.isEditing);
    },
    routeToLinkView(key, type) {
      if(type.toLowerCase() == "customer link") {
        this.$router.push({ path: `/customer/${key}` })
      }
      if(type.toLowerCase() == "group link") {
        this.$router.push({ path: `/group/${key}` })
      }
      if(type.toLowerCase() == "user link") {
        this.$router.push({ path: `/user/${key}` })
      }
    },
  },
  computed: {
    isActiveState() {
      return this.accountDetails.accountStatus === "ACTIVE"
        // this.accountDetails.accountStatus === "DORMANT"
        ? true
        : false;
    },
  },
  mounted() {
    this.getBranchDetails();
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
};
</script>
